@import '~antd/dist/antd.css';

.small-table .ant-table-thead .ant-table-cell {
  background-color: #fff;
  color: #666;
  padding: 10px 16px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
}
/* .ant-btn-info span{
  color: #666;
} */

@primary-color: #44B9EB;@border-radius-base: 4px;
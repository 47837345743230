/* 
  全局引用比较方便的使用 className 来调节样式
*/
.link {
  cursor: pointer;
  color: #44B9EB;
  user-select: none;
  
}
.link.disabled {
  color: #C0C4CC;
  cursor: default;
}
.link.danger {
  color: #E74C3C;
}

.f-b {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.f-s {
  display: flex;
  justify-content: flex-start;
}

.m-t-5 {
  margin-top: 5px;
}
.m-t-10 {
  margin-top: 10px;
}
.m-t-15 {
  margin-top: 15px;
}
.m-t-20 {
  margin-top: 20px;
}


.m-r-5 {
  margin-right: 5px;
}
.m-r-10 {
  margin-right: 10px;
}
.m-r-15 {
  margin-right: 15px;
}
.m-r-20 {
  margin-right: 20px;
}


.m-b-5 {
  margin-bottom: 5px;
}
.m-b-10 {
  margin-bottom: 10px;
}
.m-b-15 {
  margin-bottom: 21px;
}
.m-b-20 {
  margin-bottom: 20px;
}

.m-l-5 {
  margin-left: 5px;
}
.m-l-10 {
  margin-left: 10px;
}
.m-l-15 {
  margin-left: 15px;
}
.m-l-20 {
  margin-left: 20px;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}
@primary-color: #44B9EB;@border-radius-base: 4px;